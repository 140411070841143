import React from "react";
import { NavLink } from "react-router-dom";

const Navigation = () => {
  return (
    <div className="navigation">
      {/* {isOpen && (
      <div className="logo">
        <img src="assets/images/logo1.jpg" alt="21 motoring" />
      </div>
    )} */}
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <span className="icon">
              <i class="fa-solid fa-upload"></i>
            </span>
            <span className="title">&nbsp;Upload</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/images" className="nav-link">
            <span className="icon">
              <i class="fa-solid fa-image"></i>
            </span>
            <span className="title">&nbsp;All images</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/logout" className="nav-link">
            <span className="icon">
              <i class="fa-solid fa-right-from-bracket"></i>
            </span>
            <span className="title">&nbsp;Logout</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
