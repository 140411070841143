import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosInstance, Token } from "../../config";
import Navigation from "../Navigation";
import Bottom from "../Bottom";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const fileInputRef = useState(null);

  const resetimages = (e) => {
    setImages(null);
    setSelectedCategories([]);
    fileInputRef.current.value = "";
    // console.log("input file", fileInputRef)
  };

  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const handleSelectAll = (e) => {
    const categoryIds = categories.map((category) => category._id);
    // setSelectedCategories(categoryIds);
    if (selectedCategories.length === categoryIds.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(categoryIds);
    }
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    if (selectedCategories.includes(value)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    } else {
      setSelectedCategories([...selectedCategories, value]);
    }
  };

  console.log("selectedCategories", selectedCategories);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    for (let i = 0; i < selectedCategories.length; i++) {
      formData.append("category", selectedCategories[i]);
    }
    // formData.append("category", selectedCategories);
    if (selectedCategories.length === 0) {
      alert("Please select at least one category");
      return;
    } else {
      try {
        await axiosInstance.post("photos/images/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: Token,
          },
        });
        // alert("Images uploaded successfully");
        Swal.fire({
          icon: "success",
          title: "Images uploaded successfully",
        }).then(() => {
          window.location.reload();
        });
        setImages(null);
        setSelectedCategories([]);
      } catch (err) {
        console.log(err);
        alert("Error uploading images");
      }
    }
  };

  const getdata = async (e) => {
    // const get = "http://localhost:8000/api/category/view";
    try {
      const response = await axiosInstance.get(`category/view`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      });
      console.log(response.data);
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  const uploadDivWidth = isOpen ? "calc(100% - 300px)" : "calc(100% - 80px)";
  return (
    <>
      <div
        className={`dashboard my-custom-class ${isOpen ? "open" : "closed"} ${
          isOpen ? "" : "dashboard-closed"
        }`}
      >
        <Navigation />
        {/* {isOpen && ( */}
        <div className="logo2">
          <img src="assets/images/logo1.jpg" alt="21 motoring" />
        </div>
        {/*)}*/}
        <div className="toggle-button" onClick={toggleSidebar}>
          {isOpen ? (
            <div className="row">
              <div className="logo1 col-2">
                <i id="times" class="fa-solid fa-times"></i>
              </div>
            </div>
          ) : (
            <i id="bars" className="bars fa-solid fa-bars col-2"></i>
          )}
        </div>
      </div>
      <div
        className="main"
        style={{ width: uploadDivWidth, right: "0px" }}
        id="upload-div"
      >
        <div className="topbar">
          <h5>Welcome Admin</h5>
        </div>

        <div className="upload-main container">
          <div className="upload rounded">
            <div className="image-select pb-5 ">
              {/* <h1 className="pagehead p-3">Upload</h1> */}
              <h4>
                upload images <i class="fa-regular fa-image"></i>
              </h4>
              <input
                ref={fileInputRef}
                type="file"
                name="images"
                multiple
                onChange={handleImageChange}
                className="filebtn"
                key={selectedCategories.length}
                accept="image/*"
                onInvalid={(event) => {
                  const input = event.target;
                  input.setCustomValidity("Please select a valid image file");
                  input.reportValidity();
                }}
              />
            </div>
            <div className="categories pb-5">
              <h4>Select Categories</h4>
              <p>select categories for the selected images</p>
              <div className="categories-options">
                <div className="row">
                  <div className="option col-12 mb-3">
                    <button
                      type="btn"
                      name="categories"
                      className="selectall rounded"
                      onClick={handleSelectAll}
                    >
                      Select All
                    </button>
                  </div>
                  {categories.map((category) => (
                    <div key={category._id} className="option col-md-3">
                      <input
                        type="checkbox"
                        name="categories"
                        value={category._id}
                        className="checkbox"
                        onChange={handleCategoryChange}
                        checked={selectedCategories.includes(category._id)}
                      />
                      {category.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {images && images.length > 0 && (
              <div className="show-images rounded pb-5 ">
                <div className="preview">
                  <div className="row p-2">
                    <div>
                      <h5>Preview</h5>
                      <button
                        onClick={resetimages}
                        className="resetbtn btn btn-danger"
                      >
                        Reset <i class="fa-solid fa-rotate-right"></i>
                      </button>
                    </div>
                    {Array.from(images).map((image, index) => (
                      <div key={index} className="preview-image col-md-4">
                        <img
                          id="previewimage"
                          className="previewimage p-3"
                          src={URL.createObjectURL(image)}
                          alt="preview"
                        />
                        {/* <span>{image.name}</span> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="pt-5">
              <button
                className="submitbtn btn"
                // ref={fileInputRef}
                onClick={handleSubmit}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Bottom />
      </div>
    </>
  );
};

export default Home;
