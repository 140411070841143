import React, { useState, useEffect, useRef } from "react";
import { axiosInstance, Token, imageURL } from "../../config";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Bottom from "../Bottom";
import Navigation from "../Navigation";

const Images = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryname, setSelectedCategoryName] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  // console.log(selectedImages, "selectedImages");
  const [loading, setloading] = useState(false);
  // console.log("images", selectedCategory);
  // console.log(selectedCategory.name,'category id is this')

  // getcategories =====================================
  const getCategories = async (e) => {
    // const get = "http://localhost:8000/api/category/view";
    try {
      const response = await axiosInstance.get(`category/view`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      });
      console.log(response.data,"categories images 32");
      setCategories(response.data);
    } catch (error) {
      console.error(error,"error get categories");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  // ====================================================
  // handle category clicks ======================================================================
  const handleSelectedCategory = async (
    // allcategory,
    categoryId,
    categoryName
  ) => {
    setsearchedImages([]);
    setQuery("");
    setError("");
    if (selectedCategory === categoryId) {
      setSelectedCategory(null);
      setSelectedCategoryName(null);
      // setImages([]);
    } else {
      if (categoryId === "all") {
        setSelectedCategory("all");
        setSelectedCategoryName("All Categories");
        handleAllCategoriesClick();
        console.log(selectedCategory, "selected");
      }
      if (categoryId !== "all") {
        console.log(categoryName, "name");
        setSelectedCategory(categoryId);
        setSelectedCategoryName(categoryName);
        handleCategoryClick(categoryId);
      }
    }
    // console.log( categoryId, categoryName, "asdadhhdg");
  };
  // handle all categories click ========================

  const handleAllCategoriesClick = async () => {
    setImages([]);
    setQuery("");
    setsearchedImages([]);
    // console.log("all started", selectedCategory);

    setloading(true);
    // setSelectedCategory("all");
    setSelectedCategoryName("All Categories");
    try {
      const response = await axiosInstance.get(`photos/allimages`, {
        headers: {
          Authorization: Token,
        },
      });
      setImages(response.data);
    } catch (error) {
      console.error(error);
    }

    setloading(false);
  };
  // ======================================================
  // handle category click ====================================

  const handleCategoryClick = async (categoryId) => {
    setImages([]);
    setsearchedImages([]);
    setQuery("");
    setloading(true);
    try {
      const response = await axiosInstance.get(
        `photos/category/${categoryId}`,
        {
          headers: {
            Authorization: Token,
          },
        }
      );
      const imageData = response.data;
      console.log(imageData);
      setImages(imageData);
      console.log(images,"images")
      setError("");
    } catch (error) {
      console.error(error);
    }

    setloading(false);
  };
  // ========================================================================
  // delete single image ======================================================
  const deleteimage = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      // const del = `http://localhost:8000/api/photos/deleteimage/${id}`;

      try {
        const response = await axiosInstance.delete(
          `photos/deleteimage/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: Token,
            },
          }
        );

        const deletedata = response.data;

        if (response.status === 404 || !deletedata) {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Image deleted successfully!",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            // Reload the page to update the list of articles
            // window.location.reload();
            setImages((prevState) =>
              prevState.filter((image) => image._id !== id)
            );
            setsearchedImages((prevState) =>
              prevState.filter((image) => image._id !== id)
            );
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  // ========================================================================
  // categories dropdown toggle ======================================================

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [isOpencategories, setIsOpenCategories] = useState(false);

  function handleMouseEnter() {
    setIsOpenCategories(true);
  }

  function handleMouseLeave() {
    setIsOpenCategories(false);
  }

  function handleClick() {
    setIsOpenCategories(!isOpen);
  }
  // ========================================================================
  // search images ======================================================
  const [searchedimages, setsearchedImages] = useState([]);
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");
  const [searchedterm, setsearchedterm] = useState("");
  // console.log(error)

  const handleInputChange = async (e) => {
    setQuery(e.target.value);
    const query2 = e.target.value;
    // console.log(query2,"qrry2")
    try {
      if (query2.length > 0) {
        setloading(true);
        const response = await axiosInstance.get(
          `photos/search?q=${query2}`
        );
        if (response.status === 200) {
          setSelectedCategory(null);
          setSelectedCategoryName("");
          setsearchedImages(response.data.images);
          console.log(response.data.images);
          setsearchedterm(query2);
        }
      } else {
        setError("Please enter a value");
        setsearchedImages([]);
        console.log(error);
      }
    } catch (err) {
      console.error(err, "error");
      setsearchedImages([]);
      setError("No matches found");
      console.log(error);
    }
    // setsearchedterm()
    setloading(false);
  };
  // search form ================================
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    setloading(true);
    try {
      if (query.length > 0) {
        const response = await axiosInstance.get(
          `photos/search?q=${query}`
        );
        if (response.status === 200) {
          setSelectedCategory(null);
          setSelectedCategoryName("");
          setsearchedImages(response.data.images);
          console.log(response.data.images);
          setsearchedterm(query);
        }
      } else {
        setError("Please enter a value");
        setsearchedImages([]);
        console.log(error);
      }
    } catch (err) {
      console.error(err, "error");
      setsearchedImages([]);
      setError("No matches found");
      console.log(error);
    }
    setloading(false);
  };
  // ======================================================
  // delete many images =======================
  const handleImageChange = (e) => {
    const { value } = e.target;
    if (selectedImages.includes(value)) {
      setSelectedImages(selectedImages.filter((image) => image !== value));
    } else {
      setSelectedImages([...selectedImages, value]);
    }
  };

  const deleteSelectedImages = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover these images!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete them!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        const res = await axiosInstance.delete(
          "photos/deleteimages",
          {
            data: {
              imageIds: selectedImages,
            },
          }
        );

        console.log(res.data, res.data.deletedImages, "logs");
        if (res.status === 404 || !res.data) {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: `Deleted image's successfully!`,
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            // Reload the page to update the list of images
            // window.location.reload();
            if (selectedCategory === "all") {
              handleAllCategoriesClick(selectedCategory);
            }
            if (selectedCategory !== "all" && selectedCategory) {
              console.log(selectedCategory, "selected");
              handleCategoryClick(selectedCategory);
            }
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };
  // ========================================================================
  // sidebar width ======================================================
  const uploadDivWidth = isOpen ? "calc(100% - 300px)" : "calc(100% - 80px)";

  return (
    <>
      {/* sidebar */}
      <div
        className={`dashboard my-custom-class ${isOpen ? "open" : "closed"} ${
          isOpen ? "" : "dashboard-closed"
        }`}
      >
        <Navigation />
        {/* {isOpen && ( */}
        <div className="logo2">
          <img src="assets/images/logo1.jpg" alt="21 motoring" />
        </div>
        {/*)}*/}
        <div className="toggle-button" onClick={toggleSidebar}>
          {isOpen ? (
            <div className="row">
              <div className="logo1 col-2">
                <i id="times" class="fa-solid fa-times"></i>
              </div>
            </div>
          ) : (
            <i id="bars" className="bars fa-solid fa-bars col-2"></i>
          )}
        </div>
      </div>
      {/* sidebar end */}
      {/* right pane */}
      <div
        className="main"
        style={{ width: uploadDivWidth, right: "0px" }}
        id="upload-div"
      >
        {/* topbar */}
        <div className="topbar">
          <h5>Welcome Admin</h5>
        </div>
        {/* middle container */}
        <div className="upload-main container">
          <div className="upload rounded">
            <h2 className="text-start">
              <i class="fa-solid fa-image"></i> Preview Images
            </h2>
            {/* categories and search bar */}
            <nav className="previewnav navbar navbar-expand-lg rounded">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between"
                  id="navbarSupportedContent"
                >
                  <div
                    className="dropdown btn  col-md-3 col-12 "
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <button
                      className="dropdown-toggle btn  "
                      style={{ color: "white" }}
                      onClick={handleClick}
                    >
                      {selectedCategoryname || "categories"}
                    </button>
                    {isOpencategories && (
                      <div className="dropdown-menu">
                        <div className="list d-flex flex-wrap gap-1 justify-content-center">
                          <div className="list-group col-md-2 col-10">
                            <button
                              type="button"
                              className={`list-group-item list-group-item-action ${
                                selectedCategory === "all" ? "active" : ""
                              }`}
                              aria-current="true"
                              onClick={() => handleSelectedCategory("all")}
                            >
                              All Categories
                            </button>
                          </div>
                          {categories.map((category) => (
                            <div
                              key={category._id}
                              className="list-group col-md-2 col-10"
                            >
                              <button
                                type="button"
                                className={`list-group-item list-group-item-action ${
                                  selectedCategory === category._id
                                    ? "active"
                                    : ""
                                }`}
                                aria-current="true"
                                onClick={() =>
                                  handleSelectedCategory(
                                    category._id,
                                    category.name
                                  )
                                }
                              >
                                {category.name}{" "}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedCategoryname ? (
                    <div className="m-4">
                      <h2>{selectedCategoryname}</h2>
                    </div>
                  ) : (
                    ""
                  )}

                  <form
                    onSubmit={handleFormSubmit}
                    className="d-flex"
                    role="search"
                  >
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={query}
                      onChange={handleInputChange}
                    />
                    <button className="btn btn-outline-success" type="submit">
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </nav>
            {/* spinner */}
            {loading ? (
              <div className="d-flex justify-content-center align-items-center m-3">
                <div
                  className="spinner-border"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
                <div
                  className="spinner-grow"
                  style={{ width: "3rem", height: "3rem" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* search results */}
            <div className="searchresults">
              <ul class="list-group list-group-flush">
                {searchedimages.length > 0 ? (
                  <p className="p-2">Showing results for : {searchedterm}</p>
                ) : error ? (
                  <p className="p-2">{error}</p>
                ) : (
                  ""
                )}
                {searchedimages.map((image) => (
                  <li key={image._id} class="list-group-item">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="col-6">
                        <img
                          className="tableimage"
                          src={`${imageURL}/${image.filename}`}
                          alt={image.title}
                        />
                      </div>
                      <div className="text-start col-6">
                        <p>Name : {image.title}</p>
                        <p>
                          Date :{" "}
                          {moment(image.createdAt).format("  Do , MMM , YYYY")}
                        </p>
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteimage(image._id)}
                        >
                          <i class="fa-sharp fa-solid fa-trash"></i> REMOVE
                        </button>{" "}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* images table */}
            {images.length > 0 && selectedCategory ? (
              <div className="table-responsive rounded mt-2">
                <table class="listtable table">
                  <thead>
                    <tr className="table-items ">
                      <th className="text-center" scope="col">
                        {selectedImages.length > 0 ? (
                          <button
                            onClick={deleteSelectedImages}
                            className="btn btn-danger"
                          >
                            DELETE SELECTED
                          </button>
                        ) : (
                          "SELECT MULTIPLE"
                        )}
                      </th>
                      <th className="text-center" scope="col">
                        Sr.no.
                      </th>
                      <th scope="col">IMAGE NAME</th>
                      {/* <th scope="col">KEYWORDS</th>/ */}
                      <th scope="col">IMAGE</th>
                      <th className="text-center" scope="col">
                        DATE
                      </th>
                      <th className="text-center" scope="col">
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {images.map((element, id) => {
                      return (
                        <>
                          <tr key={id}>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                value={element._id}
                                className="checkbox justify-content-center"
                                onChange={handleImageChange}
                                checked={selectedImages.includes(element._id)}
                              />
                            </td>
                            <th className="listrow text-center" scope="row">
                              {id + 1}
                            </th>
                            <td className="listtitle">{element.title}</td>
                            {/* <td className="listkey">{element.category}</td> */}
                            <td className="listdesc">
                              <img
                                className="tableimage image-fluid"
                                src={`${imageURL}/${element.filename}`}
                                alt=""
                              />
                            </td>
                            <td className="listdate">
                              {moment(element.date).format("  Do , MMM , YYYY")}
                            </td>
                            <td className="d-flex justify-content-center gap-2 flex-wrap">
                              <div className="d-flex align-items-center">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => deleteimage(element._id)}
                                >
                                  <i class="fa-sharp fa-solid fa-trash"></i>{" "}
                                  REMOVE
                                </button>{" "}
                              </div>
                              {/*delete*/}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* bottom */}
        <Bottom />
      </div>
    </>
  );
};

export default Images;
