import React from "react";

const Bottom = () => {
  return (
    <div className="bottom">
      <div className="bottombar">
        <h6>
          <i class="fa-regular fa-copyright"></i> powered by{" "}
          <a
            className="intoggle-powered text-decoration-none"
            href="https://www.intoggle.com/"
          >
            Intoggle
          </a>
        </h6>
      </div>
    </div>
  );
};

export default Bottom;
