import axios from "axios"

export const axiosInstance = axios.create({
    // baseURL : "http://localhost:8000/api/"
    baseURL : "https://21motoring.intoggle.com/api/"
})

export const Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjIxbW90b3JpbmdhdXRoZW1haWxAMjFtb3RvcmluZy5jb20iLCJwYXNzIjoiflQ5TXtCJi5AV1RHWmNAIiwiaWF0IjoxNjgxMTQwNjY1fQ.yOww6KosziDv4lT332znIXxO6mIsF3xgolHvmMYX_C0';

export const imageURL = 'https://21motoring.intoggle.com/static';
// export const imageURL = "http://localhost:8000/static";